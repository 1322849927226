.header_landing_page-layout {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    background-color: var(--primary3);
    position: relative;

    & > img {
        position: absolute;
        top: 10px;
        left: 30px;
        height: 40px;
    }

    .container-menu-movil {
        margin-left: auto;
        display: none; /* Ocultar en pantallas grandes */
        align-items: center;
        justify-content: center;

        svg {
            color: white;
            font-size: 2.3rem;
        }
    }

    .container-actions-header {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        .btn-change-theme {
            margin-left: auto;

            svg {
                color: white;
                font-size: 1.5rem;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
    .container-actions-header a {
        text-decoration: none; /* Esto elimina la línea morada debajo de los enlaces */
    }
    
    .container-actions-header a:hover {
        text-decoration: none; /* También asegura que la línea no aparezca al pasar el cursor */
    }
    

    @media screen and (max-width: 960px) {
        .container-menu-movil {
            display: flex; /* Mostrar en pantallas pequeñas */
            
        }

        .container-actions-header {
            flex-direction: column;
            position: fixed;
            right: 0px;
            top: 0px;
            width: 0vw;
            height: 100vh;
            z-index: var(--z3);
            align-items: left;
            background-color: var(--primary3);
            transition: 0.2s all ease;
            overflow-x: hidden;

            button {
                background-color: #02EB48; /* Cambiar a color verde */
                border-color: #02EB48;
                color: white;
                font-weight: 400;
                margin-top: 20px; /* Espacio entre redes sociales y botón */
            }

            .btn-change-theme {
                margin: 0px;
            }

            .close-icon-menu-movil {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                // margin-bottom: 50px;
                margin-top: 20px;
                padding-right: 20px;

                svg {
                    color: white;
                    font-size: 1.3rem;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .social-media-icons {
                display: flex;
                justify-content: center;
                gap: 15px;
                margin-bottom: 20px;

                a {
                    color: white;
                    font-size: 1.5rem; /* Ajusta según el tamaño del icono */
                    transition: color 0.2s ease;

                    &:hover {
                        color: var(--primary1); /* Cambia a tu color principal */
                    }
                }
            }

            .logo-menu-movil {
                height: 40px;
                margin-top: 20px; /* Espacio entre botón y logo */
            }

            &.open {
                width: 100%;
                transition: 0.2s all ease;
                background-color: #111111;
                // background-color: yellow; 
                // margin-top: -30px; 
                
                
            }
        }
    }
}
