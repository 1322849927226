body {
    /* Escala de grises */
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
    /* Colores de aplicación */
    --primary: #02EB48;
    --primary-inverter: #02EB48;
    --secondary-inverter: #03133B;
    --primary2: #1F1F1F;
    --primary3: #000000;
    --secondary: #00FAEC;
    --accent: #02EB48;
    --error: #ae423f;
    --success: #53a653;
    --background: white;
    --background2: #efefef;
    --background3: #e5e5e5;
    --background-inverter: #323232;
    --background-login: white;
    --background-input: #efefef;
    --shadow: 2px 2px 12px -6px rgba(0, 0, 0, 0.6);
    --shadow-primary: 5px 4px 14px -6px var(--primary);
    --shadow-button-primary: 0 0 6px 0.05rem var(--primary);
    --shadow-button-secondary: 0 0 6px 0.05rem var(--secondary);
    --text: #000;
    --text2: #777;
    --gradient-primary: linear-gradient(90deg, rgba(0, 51, 146, 1) 0%, rgba(0, 51, 146, 0.49343487394957986) 46%, rgba(0, 51, 146, 0.16010154061624648) 100%);
    --font-title: 'Lato', sans-serif;
    --font-text: 'Lato', sans-serif;
    /* Z index */
    --z1: 1;
    --z2: 10;
    --z3: 100;
    --z4: 1000;
    --z5: 10000;
    --z6: 100000;
}

::-webkit-scrollbar {
    width: 0px;
}