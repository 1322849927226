.products-categories {
  text-align: center;
  margin-top: -10px;
  color: white;

  h2 {
    font-size: 1.8rem;
    margin-bottom: -1rem; /* Ajuste del margen inferior */
  }

  .categories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem; /* Reducir el espacio entre las tarjetas */
    
    .category-card {
      margin-top: 40px; /* Reducir el margen superior */
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(135deg, #333333 0%, #555555 100%);
      color: white;
      border-radius: 1rem;
      padding: 0.6rem; /* Reducir el padding */
      max-width: 260px; /* Reducir el tamaño máximo de la tarjeta */
      height: 220px; /* Reducir la altura de la tarjeta */
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pequeña */
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      word-wrap: break-word;
      border: none;

      &:hover {
        transform: translateY(-8px) scale(1.05);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
      }

      img {
        width: 120px; /* Reducir el tamaño de la imagen */
        height: auto;
        margin-top: -30px; /* Ajuste del margen superior */
      }

      h3 {
        margin: 0.2rem 0; /* Reducir el margen superior e inferior */
        font-size: 1.2rem; /* Reducir el tamaño del texto */
      }

      p {
        font-size: 0.9rem; /* Reducir el tamaño del texto */
      }

      button {
        background-color: white;
        color: #000000;
        border: none;
        border-radius: 25px;
        margin-top: 10px;
        padding: 8px 16px; /* Reducir padding del botón */
        font-size: 14px; /* Reducir el tamaño del texto del botón */
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: #333;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .products-categories h2 {
    font-size: 1.6rem; /* Reducir el tamaño del título */
  }

  .categories-container .category-card {
    max-width: 220px; /* Reducir el tamaño máximo de la tarjeta */
    height: 200px; /* Reducir la altura de la tarjeta */
    padding: 0.5rem;

    img {
      width: 100px; /* Reducir el tamaño de la imagen */
      margin-top: -30px;
    }

    h3 {
      font-size: 1rem; /* Reducir el tamaño del texto */
    }

    p {
      font-size: 0.8rem; /* Reducir el tamaño del texto */
    }

    button {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
}

@media (max-width: 480px) {
  .products-categories h2 {
    font-size: 1.4rem; /* Reducir aún más el tamaño del título */
  }

  .categories-container .category-card {
    max-width: 200px; /* Reducir aún más el tamaño máximo de la tarjeta */
    height: 180px; /* Reducir la altura de la tarjeta */
    padding: 0.4rem;

    img {
      width: 90px; /* Reducir aún más el tamaño de la imagen */
      margin-top: -25px;
    }

    h3 {
      font-size: 0.9rem; /* Reducir aún más el tamaño del texto */
    }

    p {
      font-size: 0.7rem; /* Reducir aún más el tamaño del texto */
    }

    button {
      font-size: 11px;
      padding: 5px 10px;
    }
  }
}
