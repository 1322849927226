.home_landing_page-layout {
    // background-color: #c94040;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 30px;

    .container-text {
        // background-color: green;
        padding: 20px;
        color: white;
        text-align: center;
        align-items: center;
        font-size: 1.2rem;
        .button-container {
            margin-top: 20px;
            align-items: center;
        }

        .subtitle {
            color: #e5e5e5f0;
            letter-spacing: 0.1rem;
            margin-bottom: 10px;
            font-size: 2.2rem;
        }
        
        .title {
            font-weight: bold;
            font-size: 2.2rem;

            .highlight-green {
                color: #00ff00;
            }
        
            .highlight-blue {
                color: #00ccff;
            }
        }
    }
    .button-container text-center {
        margin-top: 20px;
        align-items: center;
    }

    .button-container {
        margin-top: 20px;
        align-items: center;
    }

    .container-content-page {
        padding: 30px;
    }

    .container-services {
        margin-top: 20px;
    }

    .see-clients {
        margin-top: 30px;
    }

    @media (max-width: 768px) {
        .container-text {
            .subtitle {
                font-size: 1.5rem;
            }

            .title {
                font-size: 1.5rem;
            }
        }

        .container-content-page {
            padding: 15px;
        }

        .button-container {
            margin-top: 15px;
        }
    }

    @media (max-width: 480px) {
        .container-text {
            .subtitle {
                font-size: 1.2rem;
            }

            .title {
                font-size: 1.5rem;
            }
        }

        .container-content-page {
            padding: 10px;
        }

        .button-container {
            margin-top: 10px;
        }
    }
}
