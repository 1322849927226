.footer-landing {
  background-color: #111111;
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Asegura que todo esté en columna */
}

.footer-content {
  display: flex;
  flex-direction: column; /* Ordena los elementos en columna por defecto */
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.footer-section {
  width: 100%; /* Asegura que cada sección ocupe todo el ancho disponible */
  text-align: center; /* Centra el texto y los elementos en pantallas pequeñas */
  margin: 0.5rem 0;
}

.footer-logo {
  width: 150px; 
  // margin-bottom: 1rem;
  margin-left: 2rem;
}

.social-icons {
  display: flex;
  justify-content: center; /* Centra los íconos */
  margin-bottom: 1.5rem;
}
.social-icons a {
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  color: inherit; /* Mantiene el color original de los íconos */
}

.social-icons i {
  font-size: 24px; /* Ajusta el tamaño de los íconos según necesites */
  margin: 0 10px; /* Añade espacio entre los íconos */
}

.social-icons a:hover i {
  color: #000; /* Cambia el color al hacer hover, si lo deseas */
}


h4 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0.5rem;
  }
}

.footer-powered {
  margin-top: 20px;;
  font-size: 0.7rem;
  color: #ffffff;
  text-align: center;
  opacity: 0.8;
}

/* Estilos responsive */
@media screen and (min-width: 768px) {
  .footer-content {
    flex-direction: row; /* En pantallas medianas y grandes, coloca los elementos en fila */
    justify-content: space-around;
    align-items: flex-start;
  }

  .footer-section {
    text-align: left; /* Alinea el texto a la izquierda en pantallas grandes */
    margin: 0 1rem;
  }

  .footer-logo {
    width: 150px; 
    margin-left: 0rem;
  }

  .social-icons {
    justify-content: flex-start; /* Justifica a la izquierda en pantallas grandes */
  }
}

@media screen and (max-width: 480px) {
  .footer-content {
    flex-direction: column; /* Asegura que los elementos sigan en columna */
    justify-content: left;
    align-items: left;
  }

  .footer-section {
    text-align: left; /* Asegura que el texto esté centrado */
    margin: 0 1rem;
    margin-left: -7rem;

    font-size: 0.9rem;
  }

  .footer-logo {
    width: 150px;
    text-align: center;
    margin-top: 1.4rem;
    margin-left: -0.9rem;
  }

  .social-icons {
    justify-content: center; /* Mantiene los íconos centrados */
    margin-bottom: 1rem;
  }

  .footer-powered {
    order: 4; /* Asegura que "Powered by" esté al final */
    margin-top: 1rem;
  }
}
