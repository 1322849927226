.footer_landing-layout {
    h2 {
        font-size: 1.8rem; /* Ajuste ligero para pantallas medianas */
        margin-bottom: -1.5rem;
        color: #ffffff;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px; /* Reducir el espacio entre elementos */
    margin-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    
    .container-logos {
        width: 100%;
        display: flex;
        gap: 5%;
        align-items: center;
        justify-content: center;

        img {
            filter: grayscale(100%);
            width: 160px; /* Reducir tamaño para pantallas pequeñas */
            height: auto;
            max-height: 80px; /* Reducir la altura máxima */
            object-fit: contain;
        }
    }
    
    @media screen and (max-width: 1300px) {
        .container-logos {
            flex-direction: column;
            gap: 30px; /* Ajuste del espacio entre los logos en pantallas medianas */
        }
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-size: 1.5rem; /* Reducir tamaño del título en pantallas pequeñas */
        }
        
        .container-logos {
            gap: 20px; /* Reducir el espacio entre los logos */
            img {
                width: 140px; /* Reducir el tamaño de las imágenes */
                max-height: 70px; /* Reducir aún más la altura máxima */
            }
        }
    }

    @media screen and (max-width: 480px) {
        h2 {
            font-size: 1.9rem; /* Ajustar tamaño del título para pantallas muy pequeñas */
            margin-bottom: -1rem; /* Ajuste del margen inferior */
        }
        
        .container-logos {
            gap: 15px; /* Reducir más el espacio entre los logos */
            img {
                width: 120px; /* Reducir más el tamaño de las imágenes */
                max-height: 60px; /* Reducir aún más la altura máxima */
            }
        }
    }
}
