/* Fuente global */
* {
  font-family: Arial, sans-serif; /* Aplica Arial a todos los elementos */
  box-sizing: border-box; /* Asegura que padding y bordes se incluyan en el tamaño total */
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
}

.services {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
}


/* ============  Benefices Solutions ============ */
.benefices-solutions-card {
  background-color: #0f0e0e;
  border-radius: 15px;
  padding: 30px;
  color: #fff;
  width: 550px; /* Ajusta el tamaño según necesites */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.benefices-solutions-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.benefices-solutions-card-header .main-logo {
  height: 40px;
  margin-right: 20px;
}

.benefices-solutions-card h2 {
  font-size: 23px;
  color: #b3b3b3;
  margin: 0;
}

.benefices-solutions-card h1 {
  font-size: 26px;
  margin-top: 20px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #32cd32; /* Verde claro */
}

.benefices-solutions-card p {
  margin-top: 18px;
  font-size: 22px;
  color: #b3b3b3;
}

.benefices-solutions-card-items {
  margin-top: 20px;
}

.features {
  list-style-type: none;
  padding: 0;
}

.features li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.features li img {
  height: 80px;
  margin-right: 10px;
}

.features li p {
  font-size: 22px;
  color: #b3b3b3;
}

.features li p span {
  color: #32cd32; /* Verde claro */
}

/* Dashboard */
.dashboard {
  // background-color: yellow;
  display: flex;
  flex-grow: grow;
  justify-content: center;
  text-align: center;
}

.dashboard-colum1 {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-colum2 {
  // margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.card-renewable,
.card-analytics,
.card-efficiency,
.card-home-automation {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.card-renewable:hover,
.card-analytics:hover,
.card-efficiency:hover,
.card-home-automation:hover {
  transform: scale(1.05);
}

/* Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 46, 46, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(10px); /* Aplica un efecto de desenfoque al fondo */
  -webkit-backdrop-filter: blur(10px); /* Apoyo para navegadores WebKit */
}

.popup-content {
  background-color: #0f0e0e; /* Fondo negro */
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff; /* Texto blanco */
  position: relative;
}

.popup-content h1 {
  font-size: 38px;
  margin-top: 0;
  color: #32cd32; /* Verde claro */
}

.popup-content p {
  font-size: 23px;
  color: #b3b3b3; /* Gris claro */
}

.popup-descriptions-card-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Alinear a la izquierda */
  margin-bottom: 10px;
}

.popup-descriptions-card-header .main-logo {
  height: 40px;
  margin-right: 20px;
}

.popup-descriptions-card-items {
  margin-top: 20px;
}

.popup-descriptions-card-features {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.popup-descriptions-card-features li {
  display: flex;
  flex-direction: column; /* Organizar en columna */
  align-items: center;
}

.popup-descriptions-card-features li img {
  height: 100px;
  margin-bottom: 10px; /* Espacio entre el icono y el texto */
}

.popup-descriptions-card-features li p {
  font-size: 20px;
  color: #b3b3b3; /* Gris claro */
  text-align: center; /* Centrar el texto */
}

.popup-content button {
  background-color: #32cd32; /* Verde claro */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
}

.popup-content button:hover {
  background-color: #28a745;
}

.popup-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #fff; /* Blanco */
}

/* Renewable */
.card-renewable {
  position: relative;
  margin-top: 15px;
  border-radius: 10px;
  width: 350px;
  height: 300px;
  // margin-bottom: 19px;
}

.renewable-text h3 {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}

.renewable-image {
  position: relative;
  border-radius: 10px;
  width: 100%;
}

.renewable-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.renewable-text {
  background-color: rgba(0, 0, 0, 0.5); /* Color negro con 50% de opacidad */
  width: 100%;
  height: auto;
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  z-index: 1;
  margin-top: 100px;
  backdrop-filter: blur(10px); /* Aplica un efecto de difuminado */
  -webkit-backdrop-filter: blur(10px); /* Apoyo para navegadores WebKit */
}
.renewable-text p{
  font-size: 20px;
}

.renewable-text h3 {
  margin-top: 15px;
}
.renewable-text h4 {
  font-size: 20px;
}
.renewable-text button {
  background-color: white;
  color: #000000;
  border: none;
  border-radius: 25px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}


/* Analitica */
.card-analytics {
  background-color: #3c5942;
  // background-color: #7e25db;
  margin-top: 25px;
  color: white;
  border-radius: 15px;
  height: 300px;
  padding: 20px;
  text-align: center;
  width: 350px; /* Ajusta según sea necesario */
}

.card-analytics h3 {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}

.card-analytics img {
  width: 50px; /* Ajusta según sea necesario */
  margin-top: 15px;
}

.card-analytics h4 {
  font-size: 20px;
  margin-top: 15px;
  font-weight: bold;
}

.card-analytics p {
  font-size: 16px;
  margin-bottom: 20px;
}

.card-analytics button {
  background-color: white;
  color: #1c7510;
  border: none;
  border-radius: 25px;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.card-analytics button:hover {
  background-color: #f0f0f0;
}

/* Eficiencia */
.card-efficiency {
  background-color: #7f95ab;
  color: white;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  margin-top: 15px;
  height: 300px;
  width: 350px; /* Ajusta según sea necesario */
}

.card-efficiency h3 {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}

.card-efficiency img {
  width: 64px; /* Ajusta según sea necesario */
  margin-bottom: 15px;
}

.card-efficiency h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
}



.card-efficiency p {
  font-size: 16px;
  margin-bottom: 20px;
}

.card-efficiency button {
  background-color: white;
  color: #4da6ff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.card-efficiency button:hover {
  background-color: #f0f0f0;
}

/* Automatización */
.card-home-automation {
  position: relative;
  margin-top: -5px;
  // margin-bottom: 5px;
  border-radius: 10px;
  width: 350px;
  height: 300px;
}

.card-home-automation h3 {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}
.card-home-automation h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
}

.card-home-automation-image {
  position: relative;
  border-radius: 10px;
}

.card-home-automation-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  top: -130px;
}

.card-home-automation-text {
  background-color: rgba(0, 0, 0, 0.5); /* Color negro con 50% de opacidad */
  width: 100%;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  position: absolute;
  padding-top: 15px;
  top: 110px;
  z-index: 1;
  backdrop-filter: blur(10px); /* Aplica un efecto de difuminado */
  -webkit-backdrop-filter: blur(10px); /* Apoyo para navegadores WebKit */
}

.card-home-automation-text button {
  background-color: white;
  color: #000000;
  border: none;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;


}

/* Ajustes Responsive */
@media (max-width: 768px) {
  .services {
    flex-direction: column; /* Apilar elementos en columna */
  }

  .benefices-solutions-card {
    order: 1; /* Mostrar primero */
    width: 95%;
    margin-top: 80px;
    // margin: 10px auto;
    font-size: 1rem;
  }

  .benefices-solutions-card-header img {
    width: 50px; /* Ajusta el tamaño de la imagen */
  }

  .benefices-solutions-card-header h2 {
    font-size: 1.3rem;
  }

  .benefices-solutions-card h1 {
    font-size: 1.5rem;
  }

  .benefices-solutions-card p {
    font-size: 1rem;
  }

  .features li img {
    width: 40px; /* Ajusta el tamaño de la imagen */
    height: 40px; /* Ajusta el tamaño de la imagen */
    margin-right: 10px; /* Espacio entre la imagen y el texto */
  }

  .features li p {
    font-size: 1rem; /* Ajusta el tamaño del texto */
    margin-bottom: 18px;
  }

  .dashboard {
    flex-direction: column; /* Cambiar a columna en móvil */
    align-items: center;
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se ajusten en filas */
    justify-content: center;
  }

  .dashboard-colum1,
  .dashboard-colum2 {
    width: 100%; /* Hacer que las columnas ocupen el 100% del ancho */
    margin: 0;
  }

  .card-renewable,
  .card-analytics,
  .card-efficiency,
  .card-home-automation {
    width: 100%;
    margin-bottom: 60px; /* Añadir margen inferior para separar las filas */
  }

  .renewable-image img,
  .card-home-automation-image img {
    width: 100%;
    height: auto; /* Cambiado a auto para evitar distorsión */
    border-radius: 10px;
  }

  .renewable-text {
    font-size: 16px; /* Ajuste del tamaño del texto para pantallas pequeñas */
    padding: 8px;
  }

  .card-home-automation-text {
    font-size: 16px; /* Ajuste del tamaño del texto para pantallas pequeñas */
    padding: 8px;
  }

  .popup-content h1 {
    font-size: 28px; /* Reducir tamaño del título en pantallas pequeñas */
  }

  .popup-content p,
  .features li p,
  .card-analytics p,
  .card-efficiency p {
    font-size: 16px; /* Reducir tamaño del texto en pantallas pequeñas */
  }

  .popup-content button,
  .card-analytics button,
  .card-efficiency button {
    font-size: 14px; /* Reducir tamaño del botón en pantallas pequeñas */
    padding: 8px 16px;
  }
}

/* Ajustes Responsive para el Popup */
@media (max-width: 768px) {

  .popup-content {
    padding: 20px;
    width: 90%; /* Hacer que el popup ocupe más del ancho en móviles */
    max-width: 100%; /* Asegura que el ancho máximo no sea mayor al viewport */
    max-height: 90%; /* Asegura que el alto máximo no sea mayor al viewport */
    overflow-y: auto;
    border-radius: 10px; /* Disminuir el borde para pantallas más pequeñas */
  }

  .popup-content h1 {
    font-size: 24px; /* Reducir tamaño del título en pantallas pequeñas */
    margin-bottom: 15px;
  }

  .popup-content p {
    font-size: 16px; /* Reducir tamaño del texto en pantallas pequeñas */
    margin-bottom: 15px;
    text-align: justify; /* Alinear el texto para mejorar la legibilidad */
  }

  .popup-descriptions-card-features {
    grid-template-columns: 1fr; /* Cambiar a una sola columna en móviles */
    gap: 10px; /* Reducir el espacio entre los elementos */
  }

  .popup-descriptions-card-features li {
    font-size: 14px; /* Ajustar el tamaño del texto dentro de las características */
  }

  .popup-descriptions-card-features li img {
    height: 80px; /* Reducir el tamaño de las imágenes en móviles */
    margin-bottom: 8px;
  }

  .popup-content button {
    font-size: 14px; /* Reducir el tamaño del botón en pantallas pequeñas */
    padding: 8px 12px;
    margin-top: 15px;
  }

  .popup-close-icon {
    font-size: 20px; /* Reducir el tamaño del icono de cierre */
    top: 5px;
    right: 5px;
  }
}

@media (max-width: 390px) {
  .benefices-solutions-card{
    width: 96%;
    padding: 20px;
    font-size: 0.9rem; /* Ajuste del tamaño de fuente si es necesario */
    margin-top: -1px;
  }
  .card-renewable{
    width: 100%;
    padding: 20px;
    font-size: 0.9rem; /* Ajuste del tamaño de fuente si es necesario */
  }

  .renewable-text{
    // background-color: yellow;
    width: 90%;
  }
  .card-home-automation{
    width: 100%;
    padding: 20px;
    font-size: 0.9rem; /* Ajuste del tamaño de fuente si es necesario */
    margin-top: -70px;
  }
  .card-home-automation-text{
    // background-color: yellow;
    width: 89.2%;
    margin-bottom: -15px;
  }

  .card-analytics {
    width: 90%;
    // padding: 20px;
    font-size: 0.9rem; /* Ajuste del tamaño de fuente si es necesario */
    align-items: center;
    text-align: center;
    margin-left: 5%;
  }
  .card-efficiency{
    width: 90%;
    // padding: 20px;
    font-size: 0.9rem; /* Ajuste del tamaño de fuente si es necesario */
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-top: -30px;
  }
  .features li img {
    width: 30px;
    height: 30px;
  }

  .popup-content {
    padding: 15px;
    font-size: 0.9rem;
  }

  .popup-content button {
    padding: 8px 16px;
    font-size: 0.85rem;
  }
}
